<template>
  <div class="card">
    <div class="card-header"><i class="fas fa-globe-americas"></i> Sistema</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 pb-1" v-if="avalibleVersion">
          <p>Configuración de version para UI y aplicaciones moviles</p>
          <div class="alert alert-danger" role="alert">
            <i class="fas fa-exclamation-triangle"></i> Se detecto una nueva
            versión, por favor cree la versión
          </div>
          <button
            @click="openidModalVersion(false)"
            class="btn btn-outline-primary"
          >
            Nuevo
          </button>
        </div>
        <div class="col-md-12 overflow-auto">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">N.</th>
                <th scope="col">Versión</th>
                <th scope="col">Descripción</th>
                <th scope="col">Fecha creación</th>
                <th scope="col">Fecha modificación</th>
                <th scope="col">En mantenimiento</th>
                <th scope="col">Estado</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) of lstAplicationVersion" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ item.version }}</td>
                <td>{{ item.description }}</td>
                <td>{{ formatDate(item.fechaCreacion) }}</td>
                <td>{{ formatDate(item.fechaModificacion) }}</td>
                <td class="">
                  <span v-if="item.inMaintenance" class="badge rounded-pill bg-success"
                    >SI</span
                  >
                  <span v-if="!item.inMaintenance" class="badge rounded-pill bg-danger"
                    >NO</span
                  >
                </td>
                <td>
                  <span v-if="item.estado" class="badge rounded-pill bg-success"
                    >Activo</span
                  >
                  <span v-if="!item.estado" class="badge rounded-pill bg-danger"
                    >Inactivo</span
                  >
                </td>
                <td class="min-accion">
                  <button class="btn btn-info m-1" @click="editarVersion(item)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn btn-danger m-1"
                    @click="eliminarVersion(item)"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12">
          <div
            class="modal  fade   "
            id="idModalVersion"
            tabindex="-1"
            aria-labelledby="idModalVersionLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog  modal-dialog-centered  modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="idModalVersionLabel">
                    {{ openEdit ? "Editar" : "Nuevo" }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <FrmVersion
                    :itemSelected="dataSelected"
                    :edit="openEdit"
                  ></FrmVersion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FrmVersion from "../components/FrmVersion.vue";
import * as bootstrap from "bootstrap";
import { Message } from "../config/message.config";
import { mapState, mapActions, mapMutations } from "vuex";
import { AlertGeneral, AlertError, AlertConfirm } from "../util/alert.util";
import axios from "axios";
import config from "../config/app.config";
import { formatString } from "../util/tool.util";
import { ApplicationVersion } from "../model/applicationVersion.model";
export default {
  name: "TabVersion",
  data() {
    return {
      openEdit: false,
      dataSelected: null,
    };
  },
  computed: {
    ...mapState(["version"]),
    ...mapState("config", ["lstAplicationVersion"]),
    avalibleVersion() {
      let res = false;
      let item = this.lstAplicationVersion.find(
        (x) => x.version == this.version?.data?.appVersion
      );
      if (item == null) res = true;
      return res;
    },
  },
  methods: {
    ...mapMutations(["loadingOpen", "loadingClose"]),
    ...mapMutations("config", ["setModelRegister"]),
    ...mapActions("config", ["obtenerAplicationVersion"]),
    async openidModalVersion(tipo, data = null) {
      this.openEdit = tipo;
      if (data == null || tipo == false) data = new ApplicationVersion();
      this.dataSelected = data;
      let modal = new bootstrap.Modal(
        document.getElementById("idModalVersion"),
        {
          keyboard: false,
        }
      );
      modal.show();
      this.setModelRegister(modal);
    },
    formatDate(data) {
      return new Date(data).toLocaleString();
    },
    async editarVersion(item) {
      this.openidModalVersion(true, item);
    },
    async eliminarVersion(item) {
      try {
        const result = await AlertConfirm(
          Message.TITLE_QUESTION_DELETE,
          formatString(Message.DESCRIPCION_DELETE_VERSION, item.version)
        );
        if (result.isConfirmed) {
          const data = await axios.delete(
            `${config.URL}/applicationVersionSystemUi/eliminar/${item?.idApplicationVersion}`
          );
          this.loadingOpen();
          if (data.data.statusHttp == 200) {
            await this.obtenerAplicationVersion();
          }
          AlertGeneral(data.data);
        }
      } catch (error) {
        AlertError(error);
      }
      this.loadingClose();
    },
  },
  components: {
    FrmVersion,
  },
};
</script>
