<template>
  <div class="card">
    <h5 class="card-header"><i class="" :class="icon"></i> {{ titulo }}</h5>
    <div class="card-body d-flex align-items-center flex-column justify-content-between">
      <p class="card-text ">
        {{ descripcion }}
      </p>
      <div class="row">
        <div
          class="
                          col-md-12
                          d-flex
                          justify-content-center
                          align-items-center
                        "
        >
          <button class="btn btn-warning text-center" @click="callApi">
            <i class="far fa-play-circle"></i> Ejecutar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "../config/app.config";
import { mapState, mapActions, mapMutations } from "vuex";
import { AlertGeneral, AlertError, AlertConfirm } from "../util/alert.util";
import { Message } from "../config/message.config";
export default {
  name: "Program",
  props: {
    titulo: String,
    descripcion: String,
    service: String,
    icon: String,
  },
  methods: {
    ...mapMutations(["loadingOpen", "loadingClose"]),
    async callApi() {
      try {
        const result = await AlertConfirm(
          Message.TITLE_QUESTION,
          `Ejecutar: ${this.descripcion}`
        );
        if (result.isConfirmed) {
          this.loadingOpen();
          const data = await axios.get(
            `${config.URL}/configurationManagement/${this.service}`
          );
          AlertGeneral(data.data);
        }
      } catch (error) {
        AlertError(error);
      }
      this.loadingClose();
    },
  },
};
</script>
