<template>
  <div class="p-2">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          <i class="fas fa-users-cog"></i> General
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link "
          id="version-tab"
          data-bs-toggle="tab"
          data-bs-target="#version"
          type="button"
          role="tab"
          aria-controls="version"
          aria-selected="true"
        >
          <i class="fas fa-code-branch"></i>Version
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active p-2"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="card">
          <div class="card-header"><i class="fab fa-centos"></i> Sistema</div>
          <div class="card-body">
            <div class="row">
              <div
                class="col-md-3 mt-1 d-flex"
                v-for="(item, index) of programs"
                :key="index"
              >
                <ProgramComponent
                  :titulo="item.titulo"
                  :descripcion="item.descripcion"
                  :service="item.service"
                  :icon="item.icon"
                ></ProgramComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade show  p-2"
        id="version"
        role="tabpanel"
        aria-labelledby="version-tab"
      >
        <TabVersionComponent></TabVersionComponent>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramComponent from "../components/Program.vue";
import TabVersionComponent from "../components/TabVersion.vue";
import { Message } from "../config/message.config";

import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "config",
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState("config", ["programs"]),
  },
  components: {
    ProgramComponent,
    TabVersionComponent,
  },
  methods: {
     ...mapMutations(["loadingOpen", "loadingClose"]),
     ...mapActions("config", ["obtenerAplicationNotUseFile"]),
  },
   async beforeMount() {
    this.loadingOpen();
    await this.obtenerAplicationNotUseFile();
    this.loadingClose();
  },
};
</script>
