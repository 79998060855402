<template>
  <div class="w-100">
    <div class="card">
      <div class="card-header">
        Detalles
      </div>
      <div class="card-body">
        <form class="row was-validated" @submit="saveFrmData" method="post">
          <div class="col-md-6">
            <label for="validacionVersion" class="form-label">Version</label>
            <input
              type="text"
              class="form-control"
              id="validacionVersion"
              minlength="5"
              maxlength="20"
              required
              v-model="registroVersion.version"
            />
            <div class="invalid-feedback">
              No válido
            </div>
          </div>
          <div
            class="col-md-4 d-flex justify-content-center align-items-center"
          >
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="registroVersion.inMaintenance"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >En mantenimiento</label
              >
            </div>
          </div>
          <div
            class="col-md-2 d-flex justify-content-center align-items-center"
          >
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                disabled
                v-model="registroVersion.estado"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Estado</label
              >
            </div>
          </div>
          <div class="col-md-12">
            <label for="validacionDescripcion" class="form-label"
              >Descripción</label
            >
            <textarea
              type="text"
              class="form-control"
              id="validacionDescripcion"
              maxlength="30"
              v-model="registroVersion.description"
            ></textarea>
            <div class="invalid-feedback">
              No válido
            </div>
          </div>
          <div class="col-md-12 pt-1">
            <button class="btn btn-outline-primary" @click="addDetailVersion">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
          <div class="col-12">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Versión</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Aplicación</th>
                  <th scope="col">Eliminar</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(de,
                  index) of registroVersion.applicationVersionDetails"
                  :key="index"
                >
                  <th>
                    <input
                      type="text"
                      minlength="5"
                      maxlength="20"
                      class="form-control"
                      v-model="de.version"
                      required
                    />
                  </th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      minlength="5"
                      maxlength="20"
                      required
                      v-model="de.description"
                    />
                  </td>
                  <td>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="de.applicationType"
                    >
                      <option
                        v-bind:value="item"
                        v-for="(item, index) of lstAplicationType"
                        :key="index"
                        >{{ item?.name }}</option
                      >
                    </select>
                  </td>
                  <dt>
                    <button
                      class="btn btn-danger"
                      @click="removeDetailVersion(de)"
                    >
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </dt>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="col-md-12 d-flex justify-content-center align-items-center"
          >
            <button
              type="submit"
              class="btn btn-outline-success"
              :disabled="registroVersion.applicationVersionDetails.length == 0"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { ApplicationVersion } from "../model/applicationVersion.model";
import { ApplicationVersionDetail } from "../model/applicationVersionDetail.model";
import { AlertGeneral, AlertError, AlertConfirm } from "../util/alert.util";
import axios from "axios";
import config from "../config/app.config";
export default {
  name: "version",
  data() {
    return {
      registroVersion: new ApplicationVersion(),
    };
  },
  props: {
    itemSelected: null,
    edit: false,
  },
  watch: {
    itemSelected: function(newData, oldData) {
      this.registroVersion = newData;
      if (newData != null && this.edit)
        this.registroVersion = JSON.parse(JSON.stringify(newData));
      else {
        this.registroVersion.version = this.version?.data?.appVersion;
      }
    },
  },
  computed: {
    ...mapState(["version", "loading"]),
    ...mapState("config", ["lstAplicationType", "modelRegister"]),
  },
  methods: {
    addDetailVersion() {
      let item1 = new ApplicationVersionDetail();
      if (this.lstAplicationType.length > 0) {
        item1.applicationType = this.lstAplicationType[0];
      }
      //console.log(item1.ApplicationType.name);
      this.registroVersion.applicationVersionDetails.push(item1);
    },
    removeDetailVersion(item) {
      this.registroVersion.applicationVersionDetails = this.registroVersion.applicationVersionDetails.filter(
        (x) => x != item
      );
    },
    ...mapMutations(["loadingOpen", "loadingClose"]),
    ...mapActions("config", [
      "obtenerAplicationType",
      "obtenerAplicationVersion",
    ]),
    async saveFrmData(e) {
      e.preventDefault();
      let item = JSON.parse(JSON.stringify(this.registroVersion));
      this.loadingOpen();
      try {
        let url = `${config.URL}/applicationVersionSystemUi/`;
        let data = null;
        if (this.edit) {
          url += `actualizar`;
          item.fechaModificacion = new Date();
          data = await axios.put(url, item);
        } else {
          url += `insertar`;
          data = await axios.post(url, item);
        }
        if (data.data.statusHttp == 200) {
          this.modelRegister?.hide();
          await this.obtenerAplicationVersion();
        }
        AlertGeneral(data.data);
      } catch (error) {
        AlertError(error);
      }
      this.loadingClose();
    },
  },
  async beforeMount() {
    this.loadingOpen();
    await this.obtenerAplicationType();
    await this.obtenerAplicationVersion();
    this.loadingClose();
  },
};
</script>
